import { createSlice } from "@reduxjs/toolkit";

const brandSlice=createSlice({
    name:'brand',
    initialState:{
        brandData:null,
    },
    reducers:{
        setBrandData(state,action){
            state.brandData=action.payload
        }
    }
})

export const brandActions=brandSlice.actions;
export default brandSlice;