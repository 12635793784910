import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LpFormInput from '../../LpFormInput';
import { useSelector } from 'react-redux';
import tick from "../../../asset/icons/tick.svg";
import tickblue from "../../../asset/icons/blue_tick.svg";

const useStyles = makeStyles(() => ({
    LpForm:{
        width:'90%',
        margin:'20px auto',
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    formTitle:{
        fontSize:'22px',
        fontStyle:'normal',
        lineHeight:'41px',
        marginBottom:'5px',
        fontWeight:700,
        textAlign:'center',
        fontFamily:'EudoxusSans-Bold !important'
    },
    formSubPara:{
        fontSize:'14px',
        color:'#6c757d'
    },
    subHeading:{
        color:'#3f51b5',
        fontWeight:600,
        cursor:'pointer'
    }

}));

const LpForm = () => {
    const classes = useStyles();
    const brandData=useSelector(state=>state.brand.brandData);
    const prod=String(brandData && brandData.product_desc_content).split('|');

  return (
    <div className={classes.LpForm}>
        <Typography variant='h4' className={classes.formTitle}>
            {brandData && brandData.product_desc_head}
        </Typography>
        {/* <ol className='content-lists'>{prod && prod.map((post) => (
            <li className='content-list'>
              {brandData && brandData.business_id=='28762'? 
              <div className="icon-div"><img src={tickblue} /></div> : 
              <div className="icon-div"><img src={tick} /></div>
              }
              
              <div className="icon-text-div"><p className={classes.formSubPara}>{post}</p></div>
            </li>
          ))}</ol> */}
        {/* <Typography variant='p' className={classes.subHeading}>
            Already have an account? click here
        </Typography> */}
        <LpFormInput />
    </div>
  )
}

export default LpForm