import React, { Component, useState, useRef, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormGroup,
} from "reactstrap";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import _ from "lodash";
import "./style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
} from "@material-ui/core";

const camel2title = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export default function TextualInputs(props) {
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [leadName, setLeadName] = useState();
  const [leadMobile, setLeadMobile] = useState();
  const [leadEmail, setLeadEmail] = useState();
  const [leadProduct, setLeadProduct] = useState("");

  const onFormSubmit = (e) => {
    setButtonEnabled(false);
    e.preventDefault();
    props.onFormSubmit({
      name: leadName,
      mobile: leadMobile.slice(2),
      product: leadProduct,
      //email: leadEmail,
    });
  };

  useEffect(() => {
    if (props.products.length) {
      setLeadProduct(props.products[0].name);
    }
  }, [props]);

  const handleNameChange = (event) => {
    // console.log(event.target.value);
    setLeadName(event.target.value);
  };

  const handleMobileChange = (event, data) => {
    // console.log(event, data);
    if (event.length === 12) {
      console.log(data.countryCode);
      const phone_number = parsePhoneNumber(
        event,
        String(data.countryCode).toUpperCase()
      );
      console.log(
        phone_number,
        phone_number.getType(),
        isValidPhoneNumber(event, data.countryCode)
      );
      if (phone_number.getType() === "MOBILE") setButtonEnabled(true);

      if (
        data.countryCode === 'in' &&
        phone_number.getType() === "FIXED_LINE_OR_MOBILE" &&
        String(+phone_number.nationalNumber).match(/[6-9]{1}[0-9]{9}/gm)
      )
        setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
    setLeadMobile(event);
  };

  // const handleEmailChange = (event) => {
  //   // console.log(event.target.value);
  //   setLeadEmail(event.target.value);
  // };

  let btnRef = useRef();
  const onBtnClick = (e) => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">
          <center>
            {" "}
            <h2 className="formH2">Enter Your Details</h2>
            <p style={{ color: "#919191", textAlign: "center" }}>
              Please enter all your details and submit it.
            </p>
          </center>
        </CardTitle>

        <Form onSubmit={onFormSubmit} autoComplete="on">
          <div className="row mb-4 padding-text">
            {/* <Label
              htmlFor="horizontal-firstname-Input"
              className="col-sm-3 col-form-label form-label"
            >
              Your Name*
            </Label> */}
            <Col sm={9}>
              <TextField
              label="Your Name"
        
                type="text"
                variant="outlined"
                style={{ width: "320px" }}
                // className="form-control input_text"
                // id="horizontal-firstname-Input"
                required={true}
                value={leadName}
                onChange={handleNameChange}
              />
            </Col>
          </div>
          <div className="row mb-4 padding-text">
            <Label
              htmlFor="horizontal-password-Input"
              className="col-sm-3 col-form-label form-label"
            >
              Your Mobile Number*
            </Label>
            <Col sm={9}>
              {/* <input
                type="tel"
                className="form-control input_text"
                pattern="[6789]{1}[0-9]{9}"
                id="horizontal-password-Input"
                placeholder="Enter Your Mobile Number"
                required={true}
              /> */}
              <PhoneInput
          
                country={"in"}
                className="input_text1"
                value={leadMobile}
                onChange={handleMobileChange}
                showDropdown="false"
                id="MobilePhone"
                inputStyle={{
                  fontSize: "1rem",
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}
              /> 
              {/* <TextField  
                name="tel"
                autoComplete="tel"
                variant="outlined" 
                style={{ width: "350px" }} 
                value={leadMobile}
                onChange={handleMobileChange} 
                InputProps={{
                  startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                }} 
              /> */}
            </Col>
          </div>
          {props.usedCarDealer && (
            <div
              className="row mb-4 padding-text"
              style={{ padding: "15px !important" }}
            >
              <TextField
                select
                variant="outlined"
                style={{ width: "350px", padding: "15px !important" }}
                value={_.startCase(leadProduct)}
                label="Select a Car"
                onChange={(e) => setLeadProduct(e.target.value)}
              >
                {props.products.map((item, i) => {
                  return (
                    <MenuItem key={i} value={_.startCase(item.name)}>
                      {_.startCase(item.name)}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          )}

          {/* <div className="row mb-4 padding-text">
            <Label
              htmlFor="horizontal-email-Input"
              className="col-sm-3 col-form-label form-label"
            >
              Your Email ID
            </Label>
            <Col sm={9}>
              <input
                type="email"
                className="form-control input_text"
                id="horizontal-email-Input"
                value={leadEmail}
                onChange={handleEmailChange}
              />
            </Col>
          </div> */}

          <div className="row  padding-text">
            <Col sm={9}>
            {props.businessId=='28762'?
            <Button
            type="submit"
            disabled={!buttonEnabled}
            className={buttonEnabled ? "primary-btn5" : "secondary-btn"}
            style={{
              backgroundColor: buttonEnabled ? "#0F6EFF" : "#6c757d",
            }}
          >
            Verify Now
          </Button>:
                <Button
                  type="submit"
                  disabled={!buttonEnabled}
                  className={buttonEnabled ? "primary-btn2" : "secondary-btn"}
                  style={{
                    backgroundColor: buttonEnabled ? "#0F6EFF" : "#6c757d",
                  }}
                >
                  Verify Now
                </Button>
            }
            </Col>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}
