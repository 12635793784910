import React from 'react'
import "./LpCarousel.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';

const LpCarousel = () => {
    const brandData=useSelector(state=>state.brand.brandData);
    return (
        <div id='LpCarousel'>
            <Carousel autoPlay transitionTime="1000" showArrows={true} showThumbs={false}>
                {brandData && brandData.image_banner.map((img,index)=>{
                    return (
                        <div className='carouselImageDiv' key={index}>
                            <img className='carouselImg' src={img} />
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default LpCarousel