import React, { useState } from "react";
import {
  Card,
  Col,
  CardBody,
  Label,
  Form,
} from "reactstrap";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  TextField,
} from "@material-ui/core";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { brandActions } from "../app/brand-slice";
import { post } from "../helper/api_helper";
import FacebookEvent from "../helper/facebook_event";


export default function LpFormInput() {
  const pathname = window.location.pathname.replaceAll("/", "");
  const brandData = useSelector(state => state.brand.brandData);

  const [name, setName] = useState('');
  const [otp, setOtp] = useState('');
  const [phnNumber, setPhnNumber] = useState();
  const [isShowOtp, setIsShowOtp] = useState(false);

  const [isPhnNumberValid,setIsPhnNumberValid]=useState(false);
  const [isPhnNumberError,setIsPhnNumberError]=useState(false);

  const [leadId,setLeadId]=useState();
  const [isEnteredOtpValid,setIsEnteredOtpValid]=useState(true);

  const [searchParams, setSearchParams] = useSearchParams([]);
  let utmSource = searchParams.get("utm_source");
  let utmMedium = searchParams.get("utm_medium");
  let utmCampaign = searchParams.get("utm_campaign");
  let appCampaignId = searchParams.get("utm_campaign");

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const addLead = await post("/lead/web/addlead", {
        "businessId": brandData.business_id,
        "product": brandData.product_name,
        "leadName": name,
        "leadContact": phnNumber,
        "leadStatus": "NEW",
        "app_campaign_id": appCampaignId,
        "source": "WEB",
        "utm_campaign": utmCampaign,
        "utm_medium": utmMedium,
        "utm_source": utmSource
      });
      if (addLead) {
        if(addLead.data){
          setLeadId(addLead.data.leadId);
          setIsShowOtp(true);
        }else if(addLead.error){
          setIsPhnNumberError(true);
        }
      }
      if(brandData.business_id==="37763"){
        await FacebookEvent("otp_sent", {
          businessId: "37763",
          leadId: addLead.data.leadId,
          leadMobile: "91" + phnNumber,
          leadName: name,
          leadCity: "chennai",
          pixelId: "953191555733509",
          AccessToken:"EAAY1DyhlvEUBALfaMAcnyfQCgazXbnSSpv6OithaEEZBZALm7juz3VBBGcf82tpnyw6uLBinNdi10agN0dONtGcaeiPQgsKMDfdt0d57k7f6AUNUVdKZAfR1ZBQIL5MCEW4LwLvlFivLSZCRLaandpc8kwZC11eVR8dYitSQs34ZBWGvkFZCsisU",
        });
      }else{
        await FacebookEvent("lead_otp_sent", {
          businessId: brandData.business_id,
          leadId: addLead.data.leadId,
          leadMobile: "91" + phnNumber,
          leadName: name,
          leadCity: brandData.city,
          pixelId: brandData.ads_pixel_id ? brandData.ads_pixel_id : "432327128758691",
          AccessToken: brandData.system_user_access_token === null ? "EAAuk2hZBGMvwBAI0yLYbGv3ak8vUT9Rmo9ssoNr0hHcuA0CubkOGuG03oxp2ScZBXOPoWB39piVdSCPi1p1kkPzxhX5t4v8OQw5zZC5VZBsILedZAR1BEnGt2MMn1hguEKr2un13AlFa0frvocltrsKQ75spiroJE4455cjZBq4JSt3TZC1ZAWYE" : brandData.system_user_access_token,
        });
      }
    } catch (error) {}
  };

  const onFormSubmitOtp = async (e) => {
    e.preventDefault();
    try {
      const submitOTP = await post("/lead/web/validatelead", {
        leadId: leadId,
        mobile: phnNumber,
        otp: otp,
      });
      if(brandData.business_id==="37763"){
        await FacebookEvent("otp_verified", {
          businessId: "37763",
          leadId: leadId,
          leadMobile: "91" + phnNumber,
          leadName: name,
          leadCity: "chennai",
          pixelId: "953191555733509",
          AccessToken:"EAAY1DyhlvEUBALfaMAcnyfQCgazXbnSSpv6OithaEEZBZALm7juz3VBBGcf82tpnyw6uLBinNdi10agN0dONtGcaeiPQgsKMDfdt0d57k7f6AUNUVdKZAfR1ZBQIL5MCEW4LwLvlFivLSZCRLaandpc8kwZC11eVR8dYitSQs34ZBWGvkFZCsisU",
        });
      }else{
        await FacebookEvent("lead_otp_verified", {
          businessId: brandData.business_id,
          leadId: leadId,
          leadMobile: "91" + phnNumber,
          leadName: name,
          leadCity: brandData.city,
          pixelId: brandData.ads_pixel_id ? brandData.ads_pixel_id : "432327128758691",
          AccessToken: brandData.system_user_access_token === null ? "EAAuk2hZBGMvwBAI0yLYbGv3ak8vUT9Rmo9ssoNr0hHcuA0CubkOGuG03oxp2ScZBXOPoWB39piVdSCPi1p1kkPzxhX5t4v8OQw5zZC5VZBsILedZAR1BEnGt2MMn1hguEKr2un13AlFa0frvocltrsKQ75spiroJE4455cjZBq4JSt3TZC1ZAWYE" : brandData.system_user_access_token,
        });
      }

      if(brandData.business_id=='28762'){
        window.location.href = `${window.location.origin}/${pathname}/success_trade`
      }else{
        window.location.href = `${window.location.origin}/${pathname}/success`
      }
    } catch (error) {}
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  }

  const handleMobileChange = (event) => {
    if(event.target.value.length<=10){
      setPhnNumber(event.target.value);
    }
    if(event.target.value.length>9){
      setIsPhnNumberValid(true);
    }else{
      setIsPhnNumberValid(false);
    }
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={isShowOtp ? onFormSubmitOtp : onFormSubmit} autoComplete="on">
          {!isShowOtp && <div className="row mb-4 padding-text">
            <Col sm={9}>
              <TextField
                label="Name"

                type="text"
                variant="outlined"
                style={{ width: "320px" }}
                required={true}
                value={name}
                onChange={handleNameChange}
              />
            </Col>
          </div>}
          {isShowOtp && <p className="otpsent">Otp sent to your entered phone number.</p>}
          {isShowOtp && <div className="row mb-4 padding-text">
            <Col sm={9}>
              <TextField
                label="OTP"
                type="number"
                variant="outlined"
                style={{ width: "320px" }}
                required={true}
                value={otp}
                onChange={handleOtpChange}
              />
            </Col>
          </div>}
          {!isEnteredOtpValid && <p className="formError">Please enter the correct otp.</p>}
          {!isShowOtp && <div className="row mb-4 padding-text">
            <Col sm={9}>
              <TextField
                label="Your mobile number"
                type="number"
                variant="outlined"
                style={{ width: "320px" }}
                required={true}
                value={phnNumber}
                onChange={handleMobileChange}
              />
            </Col>
          </div>}
          {isPhnNumberError && <p className="formError">Please enter the correct phone number</p>}
          <button
            type="submit"
            style={{
              backgroundColor: isPhnNumberValid ? '#FFA800' : '#6c757d',
              border: 'none',
              borderRadius: '8px',
              color: 'white',
              padding: '15px 40px',
              width: '320px',
              margin: '10px 10px 40px 10px',
              cursor: 'pointer'
            }}
          >
            {isShowOtp ? 'Confirm' : 'Contact Us'}
          </button>
        </Form>
      </CardBody>
    </Card>
  );
}
