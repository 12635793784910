import React, { useEffect, useState } from "react";
import location from "../asset/icons/Location-orange.svg";
import star from "../asset/icons/star.svg";
import halfStar from "../asset/icons/half.svg";
import Dot from "../asset/icons/dot.svg";
import { Bars } from "react-loader-spinner";
import { Icon, Label } from 'semantic-ui-react'
import "../App_re.css";
//import DemoCarousel from "./HeaderCarosel";
import RealEstateCarosel from "./HeaderCaroselRealEstate";
import { Container, Row, Col, Button,Card } from "react-bootstrap";
//import ProductDescription from "./ProductDescription";
import UspRe from "./usp_re";
import MobileCard from "./re_mobile_number";
//import Test from "./test";
//import HeaderPart from "./headerPart";
// import FooterPart from "./footer.jsx";


import {get } from "../helper/api_helper";
//import { useSearchParams} from "react-router-dom";
import FacebookEvent from "../helper/facebook_event";
import ReactGA from 'react-ga';

function HomeRe() {
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const pathname = window.location.pathname.split('/');
  // const data = {
  //   path: pathname,
  // };
  ReactGA.initialize('G-87Q79MK7XP', {
    debug: true,
    titleCase: false,
    gaOptions: {
      userId: 123
    }
  });
  ReactGA.initialize('G-87Q79MK7XP');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    async function fetchData() {
      try {
        const response = await get("/bizv2/web/profile?brand_name=" + pathname[2]);

        setCustomers(() => response.data);
        setIsPageLoading(false);

        const response1 = await get(
          "/bizv2/web/products?business_id=" + response.data.business_id
        );

        setProducts(response1.data)
        
        document.title = "Zocket | "+response.data.business_name;
        //setProducts(() => response1.data);
        let pixel= response.data.ads_pixel_id===null ?'432327128758691': response.data.ads_pixel_id
       // let pixel="166074809553534";
       let access_token= response.data.system_user_access_token===null ? process.env.REACT_APP_FACEBOOK_TOKEN : response.data.system_user_access_token
        await FacebookEvent('new_lead_landing', {
          businessId: response.data.business_id,
          pixelId:pixel,
          AccessToken:access_token
        });

        // ReactGA.event({
        //   category: 'User',
        //   action: 'websiteLead'
        // });
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  return (
    <>
    {isPageLoading && (
        <Bars
          color="#FFA800"
          height={150}
          width={150}
          wrapperClass="loader"
        />
      )}
      {!isPageLoading && (
    <div className="container">
      <div className="carsel-images-div">
        {customers && (
          <RealEstateCarosel
            images={customers.image_banner}
            businessId={customers.business_id}
            buttonData={customers.button}
            businessName={customers.business_name}
            productName={customers.product_name}
            sellingPrice={customers.price_range}
            businessData={customers} 
            products={products}
            

          />
        )}
      </div>
      <hr
        style={{
            color:'#E1E1E1',
            backgroundColor: '#E1E1E1',
            width: '90%',
            textAlign: 'center',
            marginTop:'20px'
        }}
    />
      <div>
        <UspRe uspModel={customers} />
      </div>
      <div><MobileCard data={customers}    
        snapPoint={0}
       
          businessId={customers.business_id}
          city={customers.city}
          appCampaign_Id={customers.app_campaign_id}
          pixelId={customers.ads_pixel_id}
          AccessToken={customers.system_user_access_token}
          usedCarDealer={
            customers.business_category === "Used Car Dealers" ? true : false
          }
          products={products} /></div>
    </div>
    )}
    </>

  );
}

export default HomeRe;
