import React, { useEffect, useState } from "react";
import location from "../asset/icons/Location-orange.svg";
import star from "../asset/icons/star.svg";
import halfStar from "../asset/icons/half.svg";
import Dot from "../asset/icons/dot.svg";
import { Bars } from "react-loader-spinner";
//import { Container, Row, Col, Button } from "react-bootstrap";
import "../App.css";
import DemoCarousel from "./HeaderCarosel";
//import RealEstateCarosel from "./HeaderCaroselRealEstate";

import ProductDescription from "./ProductDescription";
import Usp from "./usp";
import Test from "./test";
//import HeaderPart from "./headerPart";
// import FooterPart from "./footer.jsx";


import {get } from "../helper/api_helper";
//import { useSearchParams} from "react-router-dom";
import FacebookEvent from "../helper/facebook_event";
import ReactGA from 'react-ga';
import HomeRe from "./HomeRe";

function Home() {
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isReflag,setIsReFlag]=useState(false);

  const pathname = window.location.pathname.replaceAll("/", "");
  // const data = {
  //   path: pathname,
  // };
  ReactGA.initialize('G-87Q79MK7XP', {
    debug: true,
    titleCase: false,
    gaOptions: {
      userId: 123
    }
  });
  ReactGA.initialize('G-87Q79MK7XP');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    async function fetchData() {
      try {
        const response = await get("/bizv2/web/profile?brand_name=" + pathname);

        setCustomers(() => response.data);
        setIsPageLoading(false);
        if(response.data.re_flag=='1'){
          setIsReFlag(true)
        }else{
          setIsReFlag(false)
        }
        const response1 = await get(
          "/bizv2/web/products?business_id=" + response.data.business_id
        );
        
        setProducts(response1.data)
        
        document.title = "Zocket | "+response.data.business_name;
        //setProducts(() => response1.data);
        let pixel= response.data.ads_pixel_id===null ?'432327128758691': response.data.ads_pixel_id
        let access_token= response.data.system_user_access_token===null ? process.env.REACT_APP_FACEBOOK_TOKEN : response.data.system_user_access_token
        await FacebookEvent('lead_landing', {
          businessId: response.data.business_id,
          pixelId:pixel,
          AccessToken:access_token
        });

        // ReactGA.event({
        //   category: 'User',
        //   action: 'websiteLead'
        // });
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  return (
    <>
    {isPageLoading && (
        <Bars
          color="#FFA800"
          height={150}
          width={150}
          wrapperClass="loader"
        />
      )}
      {/* {!isPageLoading && (
        (isReflag===true)?
          <HomeRe />  :  */}
            <div className="App container">
      {/* <HeaderPart /> */}
      <div className="BusinessName App-header">
        <h1 className="businessNameh2">
          {customers.business_name && customers.business_name}
        </h1>
      </div>
      
      <div className="">
        <div className="text-font">
         {customers.business_category} 
        </div>
        {/* <div>
        <img src={location} alt="location"></img> {customers.address_2+","+customers.city}
      </div> */}
      </div>

      <div className="carsel-images-div">
        {customers && (
          <DemoCarousel
            images={customers.image_banner}
            businessId={customers.business_id}
            buttonData={customers.button}
            businessData={customers} 
            products={products}

          />
        )}
      </div>
      <div>
        {customers && <ProductDescription productDesc={customers} />}
      </div>
      <div>
        <Usp uspModel={customers} />
      </div>
      <div>
        <Test  testimonialData={customers} products={products} />
      </div>
      {/* <div>
        <FooterPart CustomerData={customers}/>
      </div> */}
    </div>
       {/* )} */}
    </>

  );
}

export default Home;
