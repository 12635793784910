import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import BottomSheet from "./BottomForm.jsx";
export default function Usp(props) {
  const uspModel = props.uspModel;
  //const usp1=JSON.parse(uspModel.usp)
  const [sheetOpen, setSheetOpen] = useState(false);

  const onCloseSheet = (e) => {
    document.body.style.overflow = "scroll";
    setSheetOpen(false);
  };

  const onExploreButtonClick = (e) => {
    document.body.style.overflow = "hidden";
    console.log(e);
    setSheetOpen(true);
  };

  return (
    <Container>
      <Row>
        <Col lg="12">
          <div className="usp">
            <h2>Why Choose Us?</h2>
            <p style={{ color: "#919191", textAlign: "center", margin: "1px 15px 0px" }}>
              {uspModel.product_sub_head}
            </p>
          </div>
        </Col>
      </Row>
      <Row style={{marginTop: "2rem"}}>
        {uspModel.choose_us &&
          uspModel.choose_us.map((post) => (
            <Row style={{marginTop: "1rem" }}>
              <Col lg="3" sm="3" className="col-30 pt-0" style={{paddingTop: "0"}}>
                <img
                  src={post.icon}
                  alt="customer"
                ></img>
              </Col>
              <Col lg="9" sm="9" className="col-70">
                <h4 style={{ marginTop: 0 }}>{post.service_heading}</h4>
                <p style={{ color: "#919191" }}>{post.service_description}</p>
              </Col>
              <Col className="col-100"></Col>
            </Row>
          ))}
      </Row>
      {sheetOpen && (
        <BottomSheet
          isOpen={true}
          snapPoint={0}
          onCloseSheet={onCloseSheet}
          businessId={uspModel.business_id}
        />
      )}
    </Container>
  );
}
