import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Container, Row, Col, Button,Card } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "../App.css";
import { useState } from "react";
import BottomSheet from "./BottomForm.jsx";
import { CardBody } from "reactstrap";

function DemoCarousel(props) {
    const data = props;
    //console.log(data)
  const [sheetOpen, setSheetOpen] = useState(false);

  const onCloseSheet = (e) => {
    document.body.style.overflow = "scroll";
    setSheetOpen(false);
  };

  const onExploreButtonClick = (e) => {
    document.body.style.overflow = "hidden";
    //console.log(e);
    setSheetOpen(true);
  };

  return (
    <>
    <Card>
      <CardBody className="cardBodyStyle">
      <Carousel className="carosel-border" showThumbs={false} swipeable={false} transitionTime={2000} autoPlay={true} infiniteLoop={true} showIndicators={false}>
      {props.businessData.business_category === 'Used Car Dealers' ?
              props.products.map(item => {
                return <div className="car_card">
                <img
                  className="car_card_img"
                  src={item.media && JSON.parse(item.media)[0].url}
                />
                <div className="car_card_title">
                  <div>
                  <h4 style={{margin: 0, color: "#fff", textAlign: "left", width: "80%"}}  >{item.name}</h4>
                  <p style={{margin: 0}} >
                    Starts from{" "}
                    <span className="car_price">&#8377; {item.selling_price.toLocaleString()}</span>
                  </p>
                  </div>
                  <div  >
                    {item.promotion_tags && item.promotion_tags.map((tag, i) => {
                      if (i<2) {
                      return   <div className="car_card_chip" >{tag}</div>}
                    })}
                  
                    {/* <div className="car_card_chip">2022</div> */}
                  </div>
                </div>
                </div>
              }) 
              : 
              props.images &&  props.images.map((post) => (
                  <div>
                    <img src={post} alt="1" />
                  </div>
                ))
              
               
          }

      </Carousel>
          </CardBody>
        </Card>
    </>
  );
}

export default DemoCarousel;
