import React, { useEffect, useState } from 'react';
import "./HomeNewLp.css";
import LpAppBar from './LpAppBar/LpAppBar';
import LpCarousel from './LpCarousel/LpCarousel';
import LpForm from './LpForm/LpForm';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { brandActions } from '../../app/brand-slice';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const HomeNewLp = () => {
  const params = useParams();
  const dispatch=useDispatch();
  const [isLoading,setIsLoading]=useState(false);
  const [isNoFound,setIsNoFound]=useState(false);

  useEffect(() => {
    async function getBrandData() {
      setIsLoading(true);
      return fetch("https://api.zocket.com/bizv2/web/profile?brand_name="+params.brand_name, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            console.log(responseJson);
            if(responseJson.data){
              dispatch(brandActions.setBrandData(responseJson.data));
              setIsLoading(false);
            }else{
              setIsNoFound(true);
              setIsLoading(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getBrandData();
  }, [])

  return (
    <>
      {!isLoading && !isNoFound && <div id='homeNewLp'>
        <LpAppBar />
        <LpCarousel />
        <LpForm />
      </div>}
      {isLoading && <LoadingSpinner />}
      {isNoFound && <p className='error'>No Business Found with this name. please check the business name and try again.</p>}
    </>
  )
}

export default HomeNewLp;