import React, { Component } from "react";
import OtpInput from "react-otp-input";
import { Container, Row, Col, Button } from "react-bootstrap";

import "../App.css";

export default class OtpFormBoxLive extends Component {
  state = { otp: "" };

  handleChange = (otp) => {
    this.setState({ otp });
  };

  render() {
    return (
      <OtpInput
        value={this.state.otp}
        onChange={this.handleChange}
        numInputs={4}
        separator={<span></span>}
        placeholder="----"
        inputStyle="otpBox"
        focusStyle="otpFocus"
        isInputNum={true}
        containerStyle="otpContain"
      />
    );
  }
}
