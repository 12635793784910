import React, { Component, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormGroup,
} from "reactstrap";

import "./style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpFormBoxLive from './OtpFormBox.jsx';

export default function OtpForm(props) {
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    
    var otp_p = e.target[0].value+e.target[1].value+e.target[2].value+e.target[3].value;
    props.onFormSubmit({
      leadId: props.leadId,
      mobile: props.mobile,
      otp: otp_p,
    });
  };
  const handleOtpChange = (e) => {
    e.target.value.length >= 5 &&
      e.target.value.length <= 6 &&
      setButtonEnabled(true);
  };

  return (
    <Card>
      <CardTitle className="mb-4">
        <center> <h2 className="formH2">
        Enter OTP
          </h2>
          <p>Please enter otp to validate your number</p></center>
        </CardTitle>
      <CardBody>
        <Form onSubmit={onFormSubmit}>
          <div className="row mb-4 padding-text otp_div">
            <Col sm={9}>
              {/* <input
                type="text"
                className="form-control input_text"
                id="horizontal-firstname-Input"
                required={true}
                onChange={handleOtpChange}
              /> */}
              <OtpFormBoxLive />
            </Col>
          </div>

          <div className="row justify-content-end padding-text">
            <Col sm={9}>
            {props.businessId=='28762'?
            <Button type="submit" className="primary-btn4"> Verify OTP </Button> :
                <Button type="submit" className="primary-btn1"> Verify OTP </Button>
              }
            </Col>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}
