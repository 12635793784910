import React from 'react';
import PropTypes from 'prop-types';

//Material components
import Grid from '@material-ui/core/Grid';

//Styles
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyle = makeStyles(theme => ({
	root: {
		minHeight: 300,
		width: '100%',
		backgroundColor: '#FFF',
		boxShadow: '0px 0px 5px #c7c7c7',
		marginTop: 50,
		paddingBottom: 20,
		
		[theme.breakpoints.down('md')]: {
			minHeight: '100%',
			width: '95%',
			borderRadius: '15px',
			margin:'10px',
			marginBottom: '10%',

		},
		[theme.breakpoints.down('sm')]: {
			minHeight: 300,
			width: '95%',
			borderRadius: '15px',
			margin:'10px',
			marginBottom: '10%',
		},
		[theme.breakpoints.down('xs')]: {
			minHeight: 300,
			width:'95%	',
			borderRadius: '15px',
			margin:'10px',
			marginBottom: '10%',
		}
	},
	avatarContainer: {
		height: 100,
		width: 100,
		borderRadius: '15px',
		padding:'10px',
		backgroundColor: 'white',
		alignItems: 'center',
		marginLeft:'35%',
		[theme.breakpoints.down('md')]: {
			height: 90,
			width: 90,
			top: 0,
		},
		[theme.breakpoints.down('md')]: {
			height: 80,
			width: 80,
			top: 0,
		},
		[theme.breakpoints.down('xs')]: {
			height: 70,
			width: 70,
			top: 5,
		}
	},
	avatar: {
		width: '95%',
		height: '95%',
		backgroundColor: '#06232a',
		borderRadius: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		
		'& > p': {
			margin: 0,
			fontSize: '16px',
			fontWeight: 600,
			
			color: '#000',
			[theme.breakpoints.down('md')]: {
				fontSize: '16px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '16px',
			}
		}
	},
	body: {
		textAlign: 'left',
		marginLeft:'20px',
		'& > p': {
			lineHeight: '25px',
			textAlign:'left',
			justifyContent: 'center',
			color:'#696868',
			[theme.breakpoints.down('sm')]: {
				lineHeight: '25px'
			},
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '16px',
		}
	},
	author: {
		alignItems: 'center',
		textAlign: 'center',
		'& h3': {
			textAlign: 'center',
			marginTop:'50px',
			justifyContent: 'center',
			fontWeight:700,	
			color: '#000',
			fontSize: '16px',
			[theme.breakpoints.down('md')]: {
				fontSize: '16px'
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '16px',
			}
		},
		'& p': {
			textAlign: 'center',
			fontSize: '16px',
			color:'#989898',
			justifyContent: 'center',
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '14px',
			}
		}
	}
}));

const TestimonialCard = (props) => {
	const classes = useStyle();
	return (
		<Grid item className={[classes.root, props.className]}>
			<Grid item className={[classes.avatarContainer, 'testimonial-avatar-container'].join(' ')}>
				{props.image
					?<img className={[classes.avatar, 'testimonial-avatar'].join(' ')} src={props.image} alt="Avatar" />	
					:<div className={[classes.avatar, 'testimonial-avatar'].join(' ')}>
						<p> {props.name[0]} </p>
					</div>
				}
			</Grid>
            <Grid item className={[classes.author, 'testimonial-author'].join(' ')}>
				<Grid item>
					<h3> {props.name} </h3>
				</Grid>
				<Grid item>
					<p> {props.project} </p>
				</Grid>
			</Grid>

			<Grid item xs={11} className={[classes.body, 'testimonial-body'].join(' ')}>
				<p>
					{props.content}
				</p>
			</Grid>
			
		</Grid>
	);
}

TestimonialCard.propTypes = {
	name: PropTypes.string.isRequired,
	image: PropTypes.string,
	content: PropTypes.string.isRequired,
	project: PropTypes.string.isRequired,
	className: PropTypes.string
}

export default TestimonialCard;