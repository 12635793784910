import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import brandSlice from './brand-slice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    brand: brandSlice.reducer,
  },
});
