import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Container ,Row,Col,Button } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../App.css';
import checkform from '../asset/images/CheckForm.svg'
import { useNavigate } from "react-router-dom";
import FacebookEvent from "../helper/facebook_event";


function SuccessMsg(props) {
    // useEffect(() => {
    //     window.close();
    //     async function fetchData() {
    //       try {
    
    //         await FacebookEvent('success', {
    //           businessId: response.data[0].id,
    //           businessMobile: response.data[0].mobile
    //         });
    //       } catch (e) {
    //         console.error(e);
    //       }
    //     }
    //     fetchData();
    //   }, []);
      const closeTab = () => {
        window.location.href = `${window.location.origin}/${window.location.pathname.split("/")[1]}`
        // window.opener = null;
        // window.open("", "_self");
        // window.close();
        // window.history.go(-2);
        // document.body.hide();
      };
    //let navigate = useNavigate();
    const data=props.products
        return (
            <div className='App-container success-div justify-content-end padding-text'>
            <div className='App-header'>
                <img src={checkform} alt="success" ></img>
                <h2>Your Details Submitted. </h2>
                <p>All the details have been submitted, we will contact you soon.</p>
                <Button className='primary-btn bottom-stick' onClick={closeTab}>Close</Button>
            </div>
            </div>
        )
}

export default SuccessMsg
