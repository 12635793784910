import * as React from "react";
import Sheet, { SheetRef } from "react-modal-sheet";
import { post } from "../helper/api_helper.js";
import FacebookEvent from "../helper/facebook_event.js";
import TextualInputs from "./Form.jsx";
import close from "../asset/images/close.svg";
import OtpForm from "./OtpForm.jsx";
import { useLocation, useSearchParams } from "react-router-dom";
import ReactGA from "react-ga";
import axios from "axios";
import TagManager from "react-gtm-module";
import { init, track, trackCustom } from "fbq";
import gtag, { install } from "ga-gtag";
import GoogleEvent from "../helper/google_event.js";
// import "./BottomForm.css"

const snapPoints = [475, 200, 100];
const initialSnap = snapPoints.length - 1;
const pathname = window.location.pathname.replaceAll("/", "");
const data = {
  path: pathname,
};
// const tagManagerArgs = {
//   gtmId: 'G-87Q79MK7XP'
// }
// TagManager.initialize(tagManagerArgs)

//track('https://business.zocket.com/'+pathname)
const disableOnClose = () => {};

const BottomSheet = (props) => {
  const [otpTriggered, setOtpTriggered] = React.useState(false);
  const [leadId, setLeadId] = React.useState(null);
  const [leadMobile, setLeadMobile] = React.useState(null);
  const [businessId, setBusinessId] = React.useState(null);
  // const ref = React.useRef<SheetRef>();
  // //const snapTo = (i: number) => ref.current?.snapTo(i);
  let pixel = props.pixelId === null ? "432327128758691" : props.pixelId;
  let access_token =
    props.AccessToken === null
      ? process.env.REACT_APP_FACEBOOK_TOKEN
      : props.AccessToken;
  init(pixel);
  install("AW-10848271255");
  const [searchParams, setSearchParams] = useSearchParams([]);
  let utmSource = searchParams.get("utm_source");
  let utmMedium = searchParams.get("utm_medium");
  let utmCampaign = searchParams.get("utm_campaign");
  let utmPartner = searchParams.get("utm_source");
  let appCampaignId = searchParams.get("utm_campaign");
  let cid = searchParams.get("cid");


  {
    appCampaignId === null
      ? (appCampaignId = props.appCampaign_Id)
      : (appCampaignId = appCampaignId);
  }
  //console.log(searchParams);
  ReactGA.initialize("G-87Q79MK7XP");
  const onOtpSubmit = async (data) => {
    try {
      const submitOTP = await post("/lead/web/validatelead", {
        leadId: data.leadId,
        mobile: data.mobile,
        otp: data.otp,
      });
      if(props.businessId=="37763"){
        await FacebookEvent("otp_verified", {
          businessId: "37763",
          leadId: data.leadId,
          leadMobile: "91" + data.mobile,
          leadName: data.name,
          leadCity: "chennai",
          pixelId: "953191555733509",
          AccessToken:"EAAY1DyhlvEUBALfaMAcnyfQCgazXbnSSpv6OithaEEZBZALm7juz3VBBGcf82tpnyw6uLBinNdi10agN0dONtGcaeiPQgsKMDfdt0d57k7f6AUNUVdKZAfR1ZBQIL5MCEW4LwLvlFivLSZCRLaandpc8kwZC11eVR8dYitSQs34ZBWGvkFZCsisU",
        });
      }else{
        await FacebookEvent("lead_otp_verified", {
          businessId: props.businessId,
          leadId: data.leadId,
          leadMobile: "91" + data.mobile,
          leadName: data.name,
          leadCity: props.city,
          pixelId: pixel,
          AccessToken: access_token,
        });
      }

      await GoogleEvent("otp_verified", {
        category: "ConversionTrack",
        action: "otp_verifiy_click",
        label: "otp_verified",
      });

      trackCustom("otp_verified", { leadMobile: "91" + data.mobile });
      gtag("event", "otp_verified", { method: "Google" });

      window.dataLayer.push({
        event: "otp_verfied",
      });

      console.log(`${window.location.origin}/${pathname}/success`);
      if(props.businessId=='28762'){
        window.location.href = `${window.location.origin}/${pathname}/success_trade`
      }else{
        window.location.href = `${window.location.origin}/${pathname}/success`
      }
      //window.location.href = `${window.location.origin}/${pathname}/success`;
    } catch (error) {}
  };

  const onFormSubmit = async (data) => {
    try {
      const addLead = await post("/lead/web/addlead", {
        businessId: props.businessId,
        product: data.product,
        leadName: data.name,
        profilePicture: "",
        leadContact: data.mobile,
        leadContactedTo: "",
        leadStatus: "INITIATED",
        leadCallStartTime: "",
        leadCallEndTime: "",
        leadCallDurationInSeconds: "",
        leadCallRecording: "",
        source: "WEB",
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_partner:utmPartner,
        Cid:cid,
        app_campaign_id: appCampaignId,
      });
      setLeadMobile(data.mobile);
      setLeadId(addLead.data.leadId);
      setBusinessId(props.businessId);
      setOtpTriggered(true);

      if(props.businessId=="37763"){
        await FacebookEvent("otp_sent", {
          businessId: "37763",
          leadId: addLead.data.leadId,
          leadMobile: "91" + data.mobile,
          leadName: data.name,
          leadCity: "chennai",
          pixelId: "953191555733509",
          AccessToken:"EAAY1DyhlvEUBALfaMAcnyfQCgazXbnSSpv6OithaEEZBZALm7juz3VBBGcf82tpnyw6uLBinNdi10agN0dONtGcaeiPQgsKMDfdt0d57k7f6AUNUVdKZAfR1ZBQIL5MCEW4LwLvlFivLSZCRLaandpc8kwZC11eVR8dYitSQs34ZBWGvkFZCsisU",
        });
      }else{
        await FacebookEvent("lead_otp_sent", {
          businessId: props.businessId,
          leadId: addLead.data.leadId,
          leadMobile: "91" + data.mobile,
          leadName: data.name,
          leadCity: props.city,
          pixelId: pixel,
          AccessToken: access_token,
        });
      }

      await GoogleEvent("lead_otp_sent", {
        category: "ConversionTrack",
        action: "otp_sent_click",
        label: "lead_otp_sent",
      });

      trackCustom("otp_sent", {
        leadMobile: "91" + data.mobile,
        leadName: data.name,
      });
      ReactGA.event({
        category: "User",
        action: "lead_otp_sent",
      });

      gtag("event", "otp_sent", { method: "Google" });
      window.dataLayer.push({
        event: "otp-sent",
      });
      // window.location.href = pathname + "success";
    } catch (error) {}
  };

  return (
    <>
      <Sheet
        // ref={ref}
        isOpen={props.isOpen}
        onClose={disableOnClose}
        snapPoints={snapPoints}
        initialSnap={props.snapPoint}
        onSnap={(i) => {
          console.log("> Current snap point index:", i);
        }}
      >
        <Sheet.Container>
          <Sheet.Header>
            <button
              onClick={props.onCloseSheet}
              style={{
                float: "right",
                margin: "15px",
                fontSize: "24px",
                background: "#fff",
                border: "1px",
              }}
            >
              <img src={close} alt="X"></img>
            </button>
          </Sheet.Header>
          <Sheet.Content>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {!otpTriggered && (
                <TextualInputs
                  onFormSubmit={onFormSubmit}
                  usedCarDealer={props.usedCarDealer}
                  products={props.products}
                  businessId={props.businessId}
                />
              )}
              {otpTriggered && (
                <OtpForm
                  leadId={leadId}
                  mobile={leadMobile}
                  onFormSubmit={onOtpSubmit}
                  businessId={props.businessId}
                />
              )}
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
};

export default BottomSheet;
