import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "../App.css";
import tick from "../asset/icons/tick.svg";
import tickblue from "../asset/icons/blue_tick.svg";
function ProductDescription(props) {
  const { product_desc_head, product_desc_content ,business_id} = props.productDesc;
  const prod=String(product_desc_content).split('|');
  return (
    
    <div className="prod_desc">
      
      <h2>{String(product_desc_head)}</h2>
      <ol>{prod.map((post) => (
            <li>
              {business_id=='28762'? 
              <div className="icon-div"><img src={tickblue} /></div> : 
              <div className="icon-div"><img src={tick} /></div>
              }
              
              <div className="icon-text-div"><p>{post}</p></div>
            </li>
          ))}</ol>


    </div>
  );
}

export default ProductDescription;
