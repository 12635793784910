import React from "react";
import Home from "./pages/Home.jsx";
import HomeRe from "./pages/HomeRe.jsx";

import SuccessMsg from "./pages/Success.jsx";
import SuccessMsgTrade from "./pages/SuccessTrade.jsx"
import ReactGA from 'react-ga';
import Hotjar from '@hotjar/browser';
//import Helmet from 'react-helmet';
import TagManager from 'react-gtm-module'
import gtag, { install } from 'ga-gtag';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeNewLp from "./pages/HomeNewLp/HomeNewLp.jsx";

function App() {
  install('AW-10848271255');
  ReactGA.initialize('G-87Q79MK7XP');
  // const tagManagerArgs = {
  //   gtmId: 'G-87Q79MK7XP'
  // }
  // TagManager.initialize(tagManagerArgs)
  window.dataLayer.push({
    event: 'pageview'
  });
  
  const pathname = window.location.pathname;
  const siteId = 3080167;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
  const newPage = pathname;
  Hotjar.stateChange(newPage);

  return (
    <>
      <head>
      <meta
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        name="viewport"/>
</head>
      
      <Router>
        <Routes>
        <Route path="/">
            <Route index={true} path=":brand_name" element={<Home />} />
            <Route index={true} path="v2/:brand_name" element={<HomeRe />} />
            <Route index={true} path="web/:brand_name" element={<HomeNewLp />} />
            <Route index={false} path=":brand_name/success" element={<SuccessMsg />} />
            <Route index={false} path=":brand_name/success_trade" element={<SuccessMsgTrade />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
