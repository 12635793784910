import React from "react";
import ReactGA from "react-ga";

export default async function GoogleEvent(googleType, googleData) {
    ReactGA.initialize('G-M49KXHHB0K');
    try {
        ReactGA.event(googleData.category, googleData.action, googleData.label);
        return true;
    }catch (error) {
        console.error(error);
        return false;
      }
}