import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import PhoneIcon from '@material-ui/icons/Phone';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  app:{
    backgroundColor:'white',
    color:'black'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontSize:'2rem',
    color:'#3f51b5',
  },
  title: {
    flexGrow: 1,
    fontSize: "1.5rem",
    fontWeight: 800,
  },
  phone:{
    color:'#3f51b5',
    display:'flex',
    marginRight:'1rem',
    alignItems:'center',
    gap:'0.1rem',
    fontWeight:600,
  },
  phoneIcon:{
    fontSize:'1.8rem'
  }
}));

export default function LpAppBar() {
  const classes = useStyles();
  const brandData=useSelector(state=>state.brand.brandData);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.app}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {brandData && brandData.business_name}
          </Typography>
          {/* <Typography className={classes.phone}><PhoneIcon className={classes.phoneIcon} />{brandData && brandData.mobile}</Typography> */}
          <MenuIcon className={classes.menuButton} />
        </Toolbar>
      </AppBar>
    </div>
  );
}

