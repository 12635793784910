import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import BottomSheet from "./BottomForm.jsx";
import styled from "styled-components";
import configuration from "../asset/icons/configuration.png";
import amenties from "../asset/icons/amenties.png";
import nearby from "../asset/icons/nearby.png";

export default function Usp(props) {
  const uspModel = props.uspModel;
  //const usp1=JSON.parse(uspModel.usp)
  const [sheetOpen, setSheetOpen] = useState(false);

  const onCloseSheet = (e) => {
    document.body.style.overflow = "scroll";
    setSheetOpen(false);
  };

  const onExploreButtonClick = (e) => {
    document.body.style.overflow = "hidden";
    console.log(e);
    setSheetOpen(true);
  };

  // useEffect(() => {
  //   console.log(uspModel, "USPMODEL");
  // }, [uspModel]);

  return (
    <Container>
      <Row style={{ marginTop: "2rem",height:"400px"}}>
        {uspModel.choose_us &&
          uspModel.choose_us.map((post) => (
            <Feature className="feature">
              <img src={post.icon} />
              <div>
                <p>{post.service_heading}</p>
                <span>{post.service_description}</span>
              </div>
            </Feature>
          ))}

        {/* <Feature>
          <img src={amenties} />
          <div>
            <p>Amenties</p>
            <span>
              Swimming pool &bull; Gym &bull; Workspace &bull; Badminton
            </span>
          </div>
        </Feature>
        <Feature>
          <img src={nearby} />
          <div>
            <p>Nearby</p>
            <span>To marina beach, Airport and Metro</span>
          </div>
        </Feature> */}

        {/* {uspModel.choose_us &&
          uspModel.choose_us.map((post) => (
            <Row style={{ marginTop: "1rem" }}>
              <Col
                lg="3"
                sm="3"
                className="col-30 pt-0"
                style={{ paddingTop: "0" }}
              >
                <img src={post.icon} alt="customer"></img>
              </Col>
              <Col lg="9" sm="9" className="col-70">
                <h4 style={{ marginTop: 0 }}>{post.service_heading}</h4>
                <p style={{ color: "#919191" }}>{post.service_description}</p>
              </Col>
              <Col className="col-100"></Col>
            </Row>
          ))} */}
      </Row>
      {/* {sheetOpen && (
        <BottomSheet
          isOpen={true}
          snapPoint={0}
          onCloseSheet={onCloseSheet}
          businessId={uspModel.business_id}
          data={props.data}
        />
      )} */}
    </Container>
  );
}

export const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.6rem 1.3rem;

  img {
    margin-right: 0.8rem;
    margin-top: 0.2rem;
    width:20px;
  }

  p {
    color: #000;
    margin-bottom: 0.1rem;
    font-weight: 600;
  }

  span {
    color: #00000080;
  }
`;
