import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Naren from "../asset/images/test1.png";
import Kamal from "../asset/images/test2.png";
import Aromal from "../asset/images/test3.png";
import "./style.css";
import TestimonialCard from "./testimonial";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import BottomSheet from "./BottomForm";

export default function Test(props) {
  const cardsData = props.testimonialData;

  console.log(cardsData);

  const [sheetOpen, setSheetOpen] = useState(false);

  const onCloseSheet = () => {
    document.body.style.overflow = "scroll";
    setSheetOpen(false);
  };

  const onExploreButtonClick = (e) => {
    document.body.style.overflow = "hidden";
    setSheetOpen(true);
  };

  return (
    <div className="test-main-div">
      <Row>
        <Col>
          <h2>What our Clients say</h2>
          {cardsData.business_id=='28762'? '' :
          <p style={{ color: "#919191", textAlign: "center",  margin: "0px 15px" }}>{cardsData.product_sub_head}</p>
          }
          </Col>
      </Row>
      <Carousel
        className="carosel-border padding-text"
        showThumbs={false}
        swipeable={false}
        transitionTime={2000}
        autoPlay={true}
        infiniteLoop={true}
      >
        {cardsData.testimonial &&
          cardsData.testimonial.map((card) => (
            <TestimonialCard
              name={card.testimonial_name}
              content={card.testimonial_comment}
              image={card.testimonial_image}
              project={card.testimonial_role}
            />
          ))}
      </Carousel>
      <br />{" "}
      <Col>
      <>
      {cardsData.business_id=='28762'?
            <Button className="primary-btn4" onClick={onExploreButtonClick}>
            {cardsData.button}
          </Button>
            :  
            <Button className="primary-btn" onClick={onExploreButtonClick}>
          {cardsData.button}
        </Button>
          }
      </>
      </Col>
      {sheetOpen && (
        <BottomSheet
          isOpen={true}
          snapPoint={0}
          onCloseSheet={onCloseSheet}
          businessId={cardsData.business_id}
          city={cardsData.city}
          appCampaign_Id={cardsData.app_campaign_id}
          pixelId={cardsData.ads_pixel_id}
          AccessToken={cardsData.system_user_access_token}
          usedCarDealer={
            cardsData.business_category === "Used Car Dealers" ? true : false
          }
          products={props.products}
        />
      )}
    </div>
  );
}
