import axios from "axios";
import sha256 from "crypto-js/sha256";
import {useState,useEffect} from 'react'

import { init, track ,trackCustom} from 'fbq';
//const pixel = '432327128758691'



const facebookGraphApi = axios.create({
  baseURL: "https://graph.facebook.com/v15.0/",
});
export default async function FacebookEvent(eventType, eventData) {
  
  try {
    const pixel = eventData.pixelId;
    init(pixel,{external_id:eventData.businessId});
    //track('https://business.zocket.com'+window.location.pathname)

    await facebookGraphApi.post(
      `${eventData.pixelId}/events?access_token=${eventData.AccessToken}`,
      {
        data: [
          {
            event_name: eventType,
            event_time: Math.floor(new Date() / 1000),
            action_source: "website",
            user_data: {
              ph: [
                sha256(
                  eventData.leadMobile
                ).toString(),
              ],
              fn:[
                sha256(
                  eventData.leadName
                ).toString(),
              ],
              ct:[
                sha256(
                  eventData.leadCity
                ).toString(),
              ],
              country:[
                sha256(
                  "India"
                ).toString(),
              ],
              //client_ip_address:{ip},
              //lead_id:[eventData.leadId]
            },
            custom_data: eventData,
          },
        ]
      }
    );
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
