import React from "react"
import './LoadingSpinner.css';
import loader from './rocket-loader.gif';

const LoadingSpinner = (props) => {
  return (
    <div className="spinner" style={{height:props.height}}>
      <img src={loader} alt="loader" />
    </div>
  );
}

export default LoadingSpinner;
