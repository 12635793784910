import React, { Component, useEffect } from "react";
import ReactDOM from "react-dom";
import { Container, Row, Col, Button,Card } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "../App_re.css";
import { useState } from "react";
import { CardBody } from "reactstrap";
import bus_name from "../asset/images/ellipse3.svg";
import { Icon, Label } from 'semantic-ui-react'
import styled from "styled-components";
import { CarouselProvider, Slider, Slide, Image, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

function RealEstateCarosel(props) {
    const data = props;
    const BusinessData = props.businessData;
    const ProductData = props.products;
    let i=0;
    //console.log(data)
  const [sheetOpen, setSheetOpen] = useState(false);

  const onCloseSheet = (e) => {
    document.body.style.overflow = "scroll";
    setSheetOpen(false);
  };

  const onExploreButtonClick = (e) => {
    document.body.style.overflow = "hidden";
    //console.log(e);
    setSheetOpen(true);
  };

  useEffect(() => {
    console.log(props, "TTTTTT")
  }, [props])

  return (
    <>
    <Card>
      <CardBody className="cardBodyStyleRE">
        <Track>
        {props.images &&  props.images.map((post,i) => (
          <ImageWrapper>
            <img src={post} />
            <ImageIndex>{(i+1)} / {props.images.length}</ImageIndex>
          </ImageWrapper>
        ))}
        </Track>
      {/* <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={75}
        totalSlides={3}
        touchEnabled="true"
        visibleSlides={1.10}
        isPlaying="true"
      >
        <Slider>
        {props.images &&  props.images.map((post,i) => (
                  <Slide  index={i} className="new_slide">
                    <ImageWrapper>
                    <Image src={post} alt="1"  />
                      <ImageIndex>{(i+1)/props.images.length }</ImageIndex>
                    </ImageWrapper>

                    </Slide>
                ))  
        }
        </Slider>
      </CarouselProvider> */}

      {/* <Carousel className="carosel-border-RE" showThumbs={false} swipeable={false} transitionTime={2000} autoPlay={true} infiniteLoop={true} showIndicators={false}>
      {props.images &&  props.images.map((post) => (
                  <div>
                    <img src={post} alt="1" />
                  </div>
                ))        
          }
      </Carousel> */}
        </CardBody>
        </Card>
        <Row>
          <NamePrice className="nameprice">
            <h2>{props.businessData.product_name}</h2>
            <p>{props.businessData.price_range}</p>
          </NamePrice>
          <BusinessName className="businessname">
            <img src={bus_name} />
            <p><span>by</span> {props.businessData.brand_name}</p>
          </BusinessName>
        {/* <Col lg={8}>
            <div className="BusinessNameRE1">
                 <h1>{props.productName}888</h1>
                 <div className="BusinessNameSubRE1">
                     <img src={bus_name}/>
                 </div>
                 <div className="BusinessNameSubRE2">
                    <p className="re_bus_sub">by {props.businessName}</p>
                 </div>
                 
            </div>
        </Col>
          <Col lg={4}>
          <div className="BusinessNameRE2">
                 <p className="re_price">{props.sellingPrice}</p>
            </div>
          </Col> */}
    </Row>
    <Row>
        <Col lg={12}>
            <div className="BusinessNameRE3">
              {JSON.parse(props.businessData.key_points).map(item => {
                return   <Label>{item}</Label>
              })}
          
            </div>
        </Col>
    </Row>
    </>
  );
}

export default RealEstateCarosel;

const Track = styled.div`
  display: flex;
  overflow: auto;
  padding: 0.5rem ;

  &>*:not(:last-child) {
    margin-right: 1rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const ImageWrapper = styled.div`
position: relative;
border-radius: 1rem;
overflow: hidden;
flex-shrink: 0;
width: 85vw;

img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`

const ImageIndex = styled.div`
  position: absolute;
  right: 0.8rem ;
  bottom: 0.8rem ;
  padding: 2px ;
  border-radius:2px;
  background-color: rgba(0,0,0,0.6);
  color: #fff;
  font-size:10px;
`

const NamePrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }

  p {
    color: #FF5C00;
    font-size: 1.4rem;
  }
`

const BusinessName = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem; 

  img {
    margin-right: 0.6rem;
  }

  p {
    color: #000;
  }

  span {
    color:#00000099;
  }

`

