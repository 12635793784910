import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Container, Row, Col } from "react-bootstrap";
import "../App_re.css";
import "../App.css";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import BottomSheet from "./BottomForm";
import { CircularProgress, Drawer, TextField } from "@material-ui/core";
import detailShared from "../asset/images/SuccessfullyDone.gif";
import OtpForm from "./OtpForm";
import OtpInput from "react-otp-input";
import FacebookEvent from "../helper/facebook_event.js";
import { init, track, trackCustom } from "fbq";
import gtag, { install } from "ga-gtag";
import GoogleEvent from "../helper/google_event.js";
import ReactGA from "react-ga";
import { post } from "../helper/api_helper.js";
import { useLocation, useSearchParams } from "react-router-dom";

function MobileCard(props) {
  const uspModel = props.data;
  const [sheetOpen, setSheetOpen] = useState(false);
  const [bottomSheet, setBottomSheet] = useState(false);
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [leadMobile, setLeadMobile] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [leadId, setLeadId] = useState("");
  const [leadName, setLeadName] = useState("");
  const [visitDate, setVisitDate] = useState("");
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [visitDateBtnDisable, setVisitDateBtnDisable] = useState(true);
  const [otpBtnDisable, setOtpBtnDisable] = useState(true);
  const [sessionID, setSessionID] = useState("");
  const [truecallerData, setTruecallerData] = useState("");
  const [trueCallerPresent, setTrueCallerPresent] = useState(true);
  const [trucallerOpened, setTruecallerOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const intervalRef = useRef();
  const phoneInput = useRef();

  let pixel = props.pixelId === null ? "432327128758691" : props.pixelId;
  //let pixel = "166074809553534";
 // "EAAY1DyhlvEUBACx9J9GQmjFERagpWapUFK2CyRJ8j6wclqmfImbpdXApRVO8Cmw6RIYoW1NWsJUz5D0f4MG04eSIdKhItzfd8ysuqAV2Boe1JhEwlxoTaQAIB1MLdL8sNN6CZBuWtfGjSebKbgPj6e2uYMvnZBUKLJg0fiTIykjeqi2ILs";
  let access_token =  props.AccessToken === null ? process.env.REACT_APP_FACEBOOK_TOKEN  : props.AccessToken;
  init(pixel);
  install("AW-10848271255");

  const [searchParams, setSearchParams] = useSearchParams([]);
  let utmSource = searchParams.get("utm_source");
  let utmMedium = searchParams.get("utm_medium");
  let utmCampaign = searchParams.get("utm_campaign");
  let utmPartner = searchParams.get("utm_source");
  let appCampaignId = searchParams.get("utm_campaign");
  let cid = searchParams.get("cid");

  const snapPoints = [475, 200, 100];
  const initialSnap = snapPoints.length - 1;
  const pathname = window.location.pathname.replaceAll("/", "");

  const onCloseSheet = (e) => {
    document.body.style.overflow = "scroll";
    setSheetOpen(false);
  };

  const onExploreButtonClick = (e) => {
    document.body.style.overflow = "hidden";
    console.log(e);
    setSheetOpen(true);
  };

  ReactGA.initialize("G-87Q79MK7XP");

  const save_name_date = async (data) => {
    try {
      const saveData = await post("/lead/web/updatedatelead", {
        visit_date: visitDate,
        leadName: leadName,
        leadId: leadId,
      });
      setStep(3);
    } catch (error) {}
  };

  useEffect(() => {
    console.log(visitDate);
  }, [visitDate]);

  useEffect(() => {
    if (!visitDate || !leadName) {
      setVisitDateBtnDisable(true);
    } else {
      setVisitDateBtnDisable(false);
    }
  }, [visitDate, leadName]);

  useEffect(() => {
    if (otp.length !== 4) {
      setOtpBtnDisable(true);
    } else {
      setOtpBtnDisable(false);
    }
  }, [otp]);

  const verifyOtp = async (data) => {
    try {
      const submitOTP = await post("/lead/web/validatelead", {
        leadId: String(leadId),
        mobile: leadMobile.slice(2),
        otp: data.otp,
      });
      await FacebookEvent("lead_otp_verified", {
        businessId: props.businessId,
        leadId: data.leadId,
        leadMobile: "91" + data.mobile,
        leadName: data.name,
        leadCity: props.city,
        pixelId: pixel,
        AccessToken: access_token,
      });

      await GoogleEvent("otp_verified", {
        category: "ConversionTrack",
        action: "otp_verifiy_click",
        label: "otp_verified",
      });

      trackCustom("otp_verified", { leadMobile: "91" + data.mobile });
      gtag("event", "otp_verified", { method: "Google" });

      window.dataLayer.push({
        event: "otp_verfied",
      });

      console.log(`${window.location.origin}/${pathname}/success`);
      setStep(2);
      // window.location.href = `${window.location.origin}/${pathname}/success`;
    } catch (error) {
      setInvalidOtp(true);
    }
  };

  const onOtpSubmit = (e) => {
    e.preventDefault();
    console.log(otp, "OTP");

    var otp_p = otp;
    verifyOtp({
      leadId: props.leadId,
      mobile: props.mobile,
      otp: otp_p,
    });
  };

  const get_otp = async (data) => {
    try {
      const addLead = await post("/lead/web/addlead", {
        businessId: props.businessId,
        product: data.product,
        leadName: data.name,
        profilePicture: "",
        leadContact: data.mobile,
        leadContactedTo: "",
        leadStatus: "INITIATED",
        leadCallStartTime: "",
        leadCallEndTime: "",
        leadCallDurationInSeconds: "",
        leadCallRecording: "",
        source: "WEB",
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_partner:utmPartner,
        Cid:cid,
        app_campaign_id: appCampaignId,
      });

      setLeadId(addLead.data.leadId);

      await FacebookEvent("lead_otp_sent", {
        businessId: props.businessId,
        leadId: addLead.data.leadId,
        leadMobile: "91" + data.mobile,
        leadName: data.name,
        leadCity: props.city,
        pixelId: pixel,
        AccessToken: access_token,
      });
      await GoogleEvent("lead_otp_sent", {
        category: "ConversionTrack",
        action: "otp_sent_click",
        label: "lead_otp_sent",
      });

      trackCustom("otp_sent", {
        leadMobile: "91" + data.mobile,
        leadName: data.name,
      });
      ReactGA.event({
        category: "User",
        action: "lead_otp_sent",
      });

      gtag("event", "otp_sent", { method: "Google" });
      window.dataLayer.push({
        event: "otp-sent",
      });
      setBottomSheet(true);
      setStep(1);
      // window.location.href = pathname + "success";
    } catch (error) {}
  };

  const handleMobileChange = (event, data) => {
    // console.log(event, data);

    if (event.length === 12) {
      console.log(data.countryCode);
      const phone_number = parsePhoneNumber(
        event,
        String(data.countryCode).toUpperCase()
      );
      console.log(
        phone_number,
        phone_number.getType(),
        isValidPhoneNumber(event, data.countryCode)
      );
      if (phone_number.getType() === "MOBILE") setButtonEnabled(true);

      if (
        data.countryCode === "in" &&
        phone_number.getType() === "FIXED_LINE_OR_MOBILE" &&
        String(+phone_number.nationalNumber).match(/[6-9]{1}[0-9]{9}/gm)
      )
        setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
    // if (event.length > 2) {
    //   console.log("HELLO", event.length);
    setLeadMobile(event);
  };

  const onFormSubmit = (e) => {
    // if (!buttonEnabled) {
    //   console.log(phoneInput);
    //   phoneInput.current.focus();
    //   return;
    // }
    setButtonEnabled(false);
    e.preventDefault();
    console.log("clicked");
    get_otp({
      name: "",
      mobile: leadMobile.slice(2),
      product: "",
      //email: leadEmail,
    });
  };

  const get_session_id = async () => {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    const utm_campaign = searchParams.get("utm_campaign");
    const utm_medium = searchParams.get("utm_medium");
    const utm_source = searchParams.get("utm_source");
    let utmPartner = searchParams.get("utm_source");
    const cid = searchParams.get("cid");

    try {
      const sessiondata = await post("/lead/truecaller/get_session_id", {
        utm_campaign: utm_campaign,
        utm_medium: utm_medium,
        utm_source: utm_source,
        Cid:cid,
        app_campaign_id: utm_campaign,
        business_id: props.businessId,
      });
      console.log(sessiondata.data.requestId);
      setSessionID(sessiondata.data.requestId);
    } catch (err) {}
  };

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator.userAgentData.platform || window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }

  useEffect(() => {
    if (sessionID && document.hasFocus()) {
      intervalRef.current = setInterval(async () => {
        setLoading(false);
        try {
          const profileData = await post("/lead/truecaller/callback_status", {
            requestId: sessionID,
          });
          console.log(profileData);
          if (profileData.exists === true) {
            await FacebookEvent("lead_otp_sent", {
              businessId: props.businessId,
              leadId: profileData.data.app_lead_id,
              leadMobile: "91" + profileData.data.mobile,
              leadName: profileData.data.lead_name,
              leadCity: props.city,
              pixelId: pixel,
              AccessToken: access_token,
            });
            setBottomSheet(true);
            setStep(2);
            setLoading(false);
            setLeadName(profileData.data.lead_name);
            setLeadId(profileData.data.app_lead_id);
            setTruecallerData(profileData.data);
            trackCustom("otp_sent_truecaller", {
              leadMobile: "91" + profileData.data.mobile,
              leadName: profileData.data.lead_name,
            });

            trackCustom("otp_verified", { leadMobile: "91" + profileData.data.mobile });
            await FacebookEvent("lead_otp_verified", {
              businessId: props.businessId,
              leadId: profileData.data.app_lead_id,
              leadMobile: "91" + profileData.data.mobile,
              leadName: profileData.data.name,
              leadCity: profileData.props.city,
              pixelId: pixel,
              AccessToken: access_token,
            });
          }
        } catch (err) {}
      }, 3000);

      setTimeout(() => {
        clearInterval(intervalRef.current);
      }, [60 * 2 * 1000]);
    }
  }, [sessionID]);

  useEffect(() => {
    if (truecallerData) {
      clearInterval(intervalRef.current);
    }
  }, [truecallerData]);

  const openTrueCaller = () => {
    if (trucallerOpened) {
      return;
    } else {
      setTruecallerOpened(true);
      window.location = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${sessionID}&partnerKey=pZ8BH2175566da59d441d98a67165c0ebc8ed&partnerName=Zocket-landing-page-prod&lang=en&skipOption=useanothernum&ctaColor=%23FF5C00&btnShape=rect`;
      setTimeout(() => {
        if (document.hasFocus()) {
          setTrueCallerPresent(false);
        }
      }, [500]);
    }
  };

  // useEffect(() => {

  // }, [document.hasFocus()]);

  useEffect(() => {
    if (!sessionID) {
      get_session_id();
    }
    if (sessionID) {
      console.log(sessionID, "IDSSD");

      // setTimeout(function () {
      //   if (document.hasFocus()) {
      //     // Truecaller app not present on the device and you redirect the user
      //     // to your alternate verification page
      //   } else {
      //     // Truecaller app present on the device and the profile overlay opens
      //     // The user clicks on verify & you'll receive the user's access token to fetch the profile on your
      //     // callback URL - post which, you can refresh the session at your frontend and complete the user  verification

      //     window.location = `truecallersdk://truesdk/web_verify?requestNonce=${sessionID}&partnerKey=tWLyh12347fd286174886ae9e1c3dfbe1a07f&partnerName=landingpage&lang=en&title=TITLE_STRING_OPTION`;
      //   }
      // }, 1000);
    }
  }, [sessionID]);

  function check_browser() {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
      return "Edge";
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") != -1 ||
      !!document.documentMode == true
    ) {
      //IF IE > 10
      return "IE";
    } else {
      return "unknown";
    }
  }

  // const check_ios = () => {
  //   var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  //   return iOS;
  // };

  useEffect(() => {
    if (check_browser() === "Safari" || check_browser() === "unknown") {
      setTrueCallerPresent(false);
      setTruecallerOpened(true);
    }
  }, []);

  useEffect(() => {
    console.log(leadMobile);
  }, [leadMobile]);

  return (
    <>
      <Wrapper>
        {/* <Row style={{ marginTop: "2rem" }}></Row> */}
        {/* <div className="cardmobileRE"> */}

        <Content>
          {/* {trueCallerPresent ? (
            <CtaBtn onClick={() => openTrueCaller()}>Contact Us</CtaBtn>
          ) : ( */}
          <>
            <PhoneInput
              country={"in"}
              // className="input_text2"
              // showDropdown="false"

              value={leadMobile}
              onChange={handleMobileChange}
              countryCodeEditable={false}
              //onlyCountries={["in"]}
              //preferredCountries={"in"}
              //disableCountryCode={true}
              // id="MobilePhone"
              inputStyle={{
                fontSize: "1rem",
              }}
              onClick={() => openTrueCaller()}
              placeholder="Enter Mobile Number"
            />

            <CtaBtn
              isDisabled={!buttonEnabled}
              onClick={onFormSubmit}
              // disabled={!buttonEnabled}
            >
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} />
              ) : (
                "Contact Us"
              )}
            </CtaBtn>
          </>
          {/* )} */}
          {/* </div> */}
        </Content>
      </Wrapper>
      <Drawer
        anchor="bottom"
        open={bottomSheet}
        onClose={() => setBottomSheet(false)}
        sx={{ backgroundColor: "rgba(0,0,0,0)" }}
      >
        <DrawerContent>
          {step === 1 && (
            <OtpContent>
              <h2>Enter OTP</h2>
              <p>Please enter otp to validate your number</p>
              <OtpInput
                value={otp}
                onChange={(e) => setOtp(e)}
                numInputs={4}
                separator={<span></span>}
                placeholder="----"
                inputStyle="otpBox"
                focusStyle="otpFocus"
                isInputNum={true}
                containerStyle="otpContain"
              />
              {invalidOtp && <span>Invalid OTP! please try again</span>}
              <CtaBtn
                isDisabled={otpBtnDisable}
                disabled={otpBtnDisable}
                onClick={onOtpSubmit}
              >
                Verify
              </CtaBtn>
            </OtpContent>
          )}
          {step === 2 && (
            <>
              <DrawerTop bg={props.data.image_banner[0]}>
                <DrawerTopContent>
                  <div>
                    <p>Contacting for</p>
                    <h2>{props.data.product_name}</h2>
                  </div>
                  <p>{props.data.price_range}</p>
                </DrawerTopContent>
              </DrawerTop>
              <Detail>
                <h2>Select visit date</h2>
                <p>Let us know the visit date, so we can schedule it</p>
                <Label>Your Name</Label>
                <TextField
                  value={leadName}
                  onChange={(e) => setLeadName(e.target.value)}
                  variant="outlined"
                />
                <Label style={{ marginTop: "0.6rem" }}>
                  When do you want to Schedule a demo?{" "}
                </Label>
                <TextField
                  value={visitDate}
                  onChange={(e) => setVisitDate(e.target.value)}
                  variant="outlined"
                  type="date"
                  placeholder="Choose a date"
                />
                <CtaBtn1
                  isDisabled={visitDateBtnDisable}
                  disabled={visitDateBtnDisable}
                  onClick={() => save_name_date()}
                >
                  Submit Details
                </CtaBtn1>
              </Detail>
            </>
          )}
          {step === 3 && (
            <>
              <SuccessContent>
                <img src={detailShared} />
                <h2>Your detail has been shared</h2>
                <p>We will contact you as soon as possible</p>
                <CtaBtn onClick={() => setBottomSheet(false)}>Continue</CtaBtn>
              </SuccessContent>
            </>
          )}
        </DrawerContent>
      </Drawer>
      {/* {true && (
        <BottomSheet
          isOpen={true}
          snapPoint={0}
          onCloseSheet={onCloseSheet}
          businessId={cardsData.business_id}
          city={cardsData.city}
          appCampaign_Id={cardsData.app_campaign_id}
          pixelId={cardsData.ads_pixel_id}
          AccessToken={cardsData.system_user_access_token}
          usedCarDealer={
            cardsData.business_category === "Used Car Dealers" ? true : false
          }
          products={props.products}
        />
      )} */}
      {/* {sheetOpen && (
        <BottomSheet
          isOpen={true}
          snapPoint={0}
          onCloseSheet={onCloseSheet}
          businessId={uspModel.business_id}
          data={props.data}
          products={props.products}
        />
      )} */}
    </>
  );
}

export default MobileCard;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  height: 160px;
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: #fff;
`;

const Content = styled.div`
  width: max-content;
  box-shadow: 0px 0px 20px rgba(91, 33, 0, 0.1);
  padding: 1rem;
  border-radius: 0.8rem;
`;

const CtaBtn = styled.button`
  width: 320px;
  margin-top: 1rem;
  background-color: #ff5c00;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  height: 54px;
  border-radius: 0.8rem;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  outline: none;
  border: none;
  /* position: fixed; */
  /* bottom: 0;
  z-index: 100; */
  background-color: ${(props) => props.isDisabled && "#ff5c00"};
  color: ${(props) => props.isDisabled && "#202020"};
`;

const CtaBtn1 = styled.button`
  width: 100%;
  margin-top: 1rem;
  background-color: #ff5c00;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  height: 54px;
  border-radius: 0.8rem;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  outline: none;
  border: none;
  background-color: ${(props) => props.isDisabled && "#ff5c00"};
  color: ${(props) => props.isDisabled && "#202020"};
`;

const DrawerContent = styled.div`
  height: max-content;
  border-radius: 2rem 2rem 0 0;
  overflow: hidden;
`;

const DrawerTop = styled.div`
  background-image: url(${(props) => props.bg});
  width: 100%;
  height: 16rem;
  background-size: cover;
  background-position: center;
  position: relative;
`;

const DrawerTopContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 14rem;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0)
  );
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1.5rem;

  p {
    font-size: 1.4rem;
    margin-bottom: 0.1rem;
    font-weight: 400;
  }

  h2 {
    color: #fff;
    font-size: 2rem;
    margin: 0;
  }
`;

export const Detail = styled.div`
  padding: 2rem 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 0.2rem;
  }

  p {
    color: #00000080;
    margin-bottom: 2rem;
  }
`;

export const Label = styled.label`
  color: #000;
  margin-bottom: 0.3rem;
`;

export const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  background-color: #fff;

  p {
    color: #00000080;
  }
  img {
    width: 250px;
  }
`;

export const OtpContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  background-color: #fff;

  span {
    color: red;
  }

  p {
    color: #00000080;
  }
`;
